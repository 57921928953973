.opc-carousel {

    .carousel-slide-content {
        padding: 2rem;
        .carousel-slide-content-inner {
            background: rgba(#fff, 0.6);
            max-width: 40rem;
            padding: 2rem;
        }
    }


    h2 {
    
        span {
            @extend .h1;
        }
    }

    



}