.basket-list-quickorder {

    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include mq($min-width: map-get($breakpoints, m)) {
        padding: 15px 0px;
    }

    > .quickorder-title {
        margin-bottom: 10px;
        width: 100%;
        font-weight: bold;
    }


    > .opc-form-field {

        width: 100%;
        position: relative;

        @include mq($min-width: map-get($breakpoints, m)) {
            flex-basis: 50%;
            width: 50%;
            max-width: 50%;
            margin-right: 1rem;
        }
    }

    #quickorder-dropdown {
        display: none;
        position: absolute;
        top: calc(100% + 0.3rem);
        width: 100%;
        padding: 0.5rem 0px;
        z-index: 10;
        background: #FFF;
        padding-right: 8px;

        &::before {
            content: '';
            width: 1.5rem;
            height: 1.5rem;
            background: transparent;
            display: none;
            border-radius: 50%;
            margin: 0 auto;
            position: absolute;
            left: 50%;
            top: 0.5rem;
            margin-left: -1.5rem;
            border: 2px solid transparent;
            border-top: 2px solid $brand-color-1;
            animation-name: rotater;
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
        @keyframes rotater {
            50% {
                transform: rotate(400deg);
            }
        }


        &.is-showing {
            display: block;

            &::before {
                display: none;
            }
        }

        &.is-loading {
            display: block;
            min-height: 3rem;
            &::before {
                display: block;
            }
        }
        #quickorder-search-result {

            width: 100%;
            margin: 0px;
            display: flex;
            flex-wrap: wrap;
            z-index: 2;
            padding: 0px;
            min-height: 3rem;
            max-height: 30rem;
            overflow-y: auto;
            font-size: 1.6rem;
            line-height: 2.2rem;

            li {

                width: 100%;
                padding-left: 0.5rem;
                display: grid;
                border-bottom: 1px solid #000;
                padding-bottom: 1rem;
                grid-template-columns: 1fr 2fr;
                grid-template-areas: 
                    "image description"
                    "image additionalInfos"
                    ". basketButton";
                align-items: center;
                background: transparent;
                margin-bottom: 0.5rem;
                min-height: 7.5rem;

                @include mq($min-width: map-get($breakpoints, xl)) {
                    grid-template-areas: 
                    "image description ."
                    ". additionalInfos basketButton";
                    border-bottom: unset;
                    padding-bottom: unset;
                }

                &:hover {
                    cursor: pointer;
                }


                .list-item-image {
                    flex-basis: 6rem;
                    margin-right: 2rem;
                    flex-shrink: 0;
                    flex-grow: 0;
                    padding: 0.5rem 0px;
                    grid-area: image;

                    img {
                        margin: 0 auto;
                        display: block;
                        max-width: 100%;
                        max-height: 100%;
                        height: 80px;
                    }
                }

                .list-item-info {
                    max-width: 100%;
                    flex-basis: 1%;
                    flex-grow: 1;
                    padding: 0.5rem 0px;
                    color: $black;
                    grid-area: description;

                    .list-item-title {
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        font-weight: bold;
                        overflow: hidden;

                        .article-flag-wrapper {
                            margin-left: 1rem;
                        }
                    }
                }

                .list-item-additional-infos {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    align-self: flex-start;
                    grid-area: additionalInfos;
                    flex-direction: column;


                    .title {
                        font-weight: bold;
                    }
                }

                .list-item-basket-button {
                    height: 100%;
                    grid-area: basketButton;

                    @include mq($min-width: map-get($breakpoints, xl)) {
                        margin-left: 2rem;
                        margin-right: 8px;
                    }

                    .opc-button {
                        width: 7.5rem;
                        height: 100%;
                    }

                    .opc-basket-button {
                        flex-wrap: wrap;
                        row-gap: 8px;
                    }

                    .basket-change-button-wrapper {
                        align-self: flex-end;
                    }

                    .basket-change-wrapper {
                        display: flex;
                        margin-right: 20px;
                    }

                    .basket-change-quantity {
                        height: 3rem;
                        width: 4rem;
                        padding: 0;
                        font-weight: bold;
                        text-align: center;
                        background: #eee;
                        border: 1px solid #eee;
                    }

                    .basket-quntity-input {
                        height: 3rem;
                        margin-right: 0;
                        width: 6rem;
                        border: 1px solid #eee;
                    }
                }

            }

            > li:last-child {
                border-bottom: none;
            }
        }
    }
}