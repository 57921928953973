/*###############################################

@Title: Theme 
@Description:
This file contains all theme relevant files

###############################################*/

// import theme specific node modules or external stuff
@import "https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i";
@import "https://fonts.googleapis.com/css2?family=Merriweather:ital@1&display=swap";
//@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

// import theme overwrites
@import "atoms/atoms";
@import "organisms/organisms";

@import "other/burger";

@import "structure/grid";
@import "structure/topbar";
@import "structure/header";
@import "structure/mainnav";
@import "structure/offcanvasnav";
@import "structure/content";
@import "structure/footer";
@import "structure/section";


// page specific styles
@import "pages/checkout";
@import "pages/contact";
@import "pages/details";
@import "pages/register-login";
@import "pages/favorites";
@import "pages/compare";
@import "pages/basket";
@import "pages/account";
@import "pages/ecrm";
@import "pages/error";
@import "pages/offline";
@import "pages/start";
@import "pages/notsupported";
@import "pages/cms";

* {
	box-sizing: border-box;
}

.opc-breadcrumb{
	.breadcrumb-pretext{
		display: none;
	}
}

.opc-notifications {
	.notifications-modal {
		z-index: 99999999;
	}
}