/*###############################################

@Title: Project Theme Config
@Description:
This file contains the config settings for the theme. It will overwrite the core vars.

###############################################*/

/*
 
 Basics
 ---------------------------------------------------- */

// Max Width and Max Width Paddings
$mw: 1200px;
$mw-padding: 3rem;

// Grid
$_opc-grid-columns: 12;
$_opc-grid-gutter-width: 15px;
$_opc-grid-column-width: 120px;
 
/*
 
 Colors
 ---------------------------------------------------- */

$brand-color-1: #e0ab12;
$brand-color-2: #003b66;
$brand-color-3: #776b5f;

$white: #fff;
$black: #000;
$gray: #ccc;

$success-color: #a0c000;
$error-color : #fa7979;
$info-color: #b0ddfa;
$warning-color: #fff8bb;

// button colors
$primary-button-color : $brand-color-2;
$secondary-button-color : #ccc;
$loud-button-color : $brand-color-1;

// state colors
$success-color : #a0c000;
$error-color : #fa7979;
$info-color : #78b3da;
$warning-color : #fff8bb;
$progress-color : #ccc;

// stock colors
$stock-on-stock : $success-color;
$stock-out-stock : $error-color;
$stock-call-stock : $info-color;
$stock-less-stock : #f0bb54;
$stock-na-stock : $error-color;

// external component colors
$selected-date-color: $brand-color-1;
$autocomplete-highlight-color: $brand-color-1;
$fileupload-error-color: $error-color;
$_opc-boolean-active-color: $brand-color-1;

// breakpoints
$breakpoints: ( 
    'tiny': 480px,
    'small': 640px,
    'medium': 800px,
    'normal': 1020px,
    'large': 1400px,
    'xs': 480px,
    's': 640px,
    'm': 800px,
    'l': 1024px,
    'xl': 1400px,
    'xxl': 1850px
);


// sizes 
$padding: ( 
    'tiny': 0.5rem, 
    'small': 0.75rem, 
    'medium': 1.25rem, 
    'normal': 1.25rem, 
    'large': 1.75rem, 
    'huge': 2rem

    );
/* !!!! DEPRECATED, JUST FOR COMPATIBLITY. USE PADDING INSTEAD !!!!! */
$SIZES: ( 'tiny': 0.5rem, 'small': 0.75rem, 'medium': 1.25rem, 'normal': 1.25rem, 'large': 1.75rem, 'huge': 2rem);

// heights
$heights: ( 
    'tiny': 2.6rem, 
    'small': 3.2rem, 
    'medium': 4rem, 
    'normal': 4rem, 
    'large': 5rem, 
    'huge': 6rem
);

$spacing: ( 
    'small': 2rem, 
    'medium': 3rem, 
    'large': 4rem
);


/*
 
 typography
 ---------------------------------------------------- */

// font-size
$_opc-base-font-size : 1.6rem;
// fluid typography 
$_opc-fluid-typo : "false";
$_opc-font-size-multiplyer : 1.2;
$_opc-font-min-width : 320px;
$_opc-font-max-width : $mw;
// font family and styles
$_opc-base-font-family : 'Roboto', sans-serif;
$_opc-base-font-weight : 400;
$_opc-base-line-height : 1.2;
$_opc-headline-font-family : "Merriweather", serif;
$_opc-headline-font-weight : 400;
$_opc-headline-line-height : 1.25;
$_opc-lead-font-family : "Arial";
$_opc-lead-font-weight : 400;
$_opc-lead-line-height : 1.25;
// headlines
$_opc-font-size-h1 : 56px;
$_opc-font-size-h2 : 46px;
$_opc-font-size-h3 : 20px;
$_opc-font-size-h4 : 18px;
$_opc-font-size-h5 : 16px;
$_opc-font-size-h6 : 14px;
$_opc-font-size-h1-max : $_opc-font-size-h1 * $_opc-font-size-multiplyer;
$_opc-font-size-h2-max : $_opc-font-size-h2 * $_opc-font-size-multiplyer;
$_opc-font-size-h3-max : $_opc-font-size-h3 * $_opc-font-size-multiplyer;
$_opc-font-size-h4-max : $_opc-font-size-h4 * $_opc-font-size-multiplyer;
$_opc-font-size-h5-max : $_opc-font-size-h5 * $_opc-font-size-multiplyer;
$_opc-font-size-h6-max : $_opc-font-size-h6 * $_opc-font-size-multiplyer;
// lead and copy text
$_opc-font-size-lead : $_opc-base-font-size * 1.4;
$_opc-font-size-lead-max : $_opc-font-size-lead * $_opc-font-size-multiplyer;
$_opc-font-size-small : 1.2rem;
$_opc-font-size-small-max : $_opc-font-size-small * $_opc-font-size-multiplyer;
$_opc-font-size-copy : $_opc-base-font-size;
$_opc-font-size-copy-max : $_opc-font-size-copy * $_opc-font-size-multiplyer;
$_opc-font-size-s : 1.2rem;
$_opc-font-size-m : 1.4rem;
$_opc-font-size-l : 1.6rem;
$_opc-font-size-xl : 1.8rem;
/*
 
 SVG Icons
 ---------------------------------------------------- */

// make svg icons available in sass as background image
$op-icon-cross-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 7.21875 5.78125 L 5.78125 7.21875 L 14.5625 16 L 5.78125 24.78125 L 7.21875 26.21875 L 16 17.4375 L 24.78125 26.21875 L 26.21875 24.78125 L 17.4375 16 L 26.21875 7.21875 L 24.78125 5.78125 L 16 14.5625 Z "/></svg>';
$op-icon-check-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 28.28125 6.28125 L 11 23.5625 L 3.71875 16.28125 L 2.28125 17.71875 L 10.28125 25.71875 L 11 26.40625 L 11.71875 25.71875 L 29.71875 7.71875 Z "/></svg>';
$op-icon-angle-right-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 12.96875 4.28125 L 11.53125 5.71875 L 21.8125 16 L 11.53125 26.28125 L 12.96875 27.71875 L 23.96875 16.71875 L 24.65625 16 L 23.96875 15.28125 Z "/></svg>';
$op-icon-angle-left-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path d="M19.031 4.281l-11 11-0.688 0.719 0.688 0.719 11 11 1.438-1.438-10.281-10.281 10.281-10.281z"></path></svg>';
$op-icon-angle-down-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><path style=" " d="M 4.21875 10.78125 L 2.78125 12.21875 L 15.28125 24.71875 L 16 25.40625 L 16.71875 24.71875 L 29.21875 12.21875 L 27.78125 10.78125 L 16 22.5625 Z "/></svg>';
$op-icon-stock-svg: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" version="1.1" width="32" height="32"><circle cx="50" cy="50" r="40" /></svg>';

$psp-icon-slider-navigation-left-svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.5 10C19.5 15.25 15.25 19.5 10 19.5C4.75 19.5 0.5 15.25 0.5 10C0.5 4.75 4.75 0.5 10 0.5C15.25 0.5 19.5 4.75 19.5 10Z" fill="#E26B6B" stroke="#D65C5C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M13.46 6.29004L9.73999 10L13.46 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.38999 6.29004L5.67999 10L9.38999 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>';
$psp-icon-slider-navigation-right-svg: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.5 10C0.5 15.25 4.75 19.5 10 19.5C15.25 19.5 19.5 15.25 19.5 10C19.5 4.75 15.25 0.5 10 0.5C4.75 0.5 0.5 4.75 0.5 10Z" fill="#E26B6B" stroke="#D65C5C" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.54001 6.29004L10.26 10L6.54001 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M10.61 6.29004L14.32 10L10.61 13.71" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>';

$slider-arrow-left: '<svg viewBox="0 0 32 32" version="1.1" width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path class="st0" d="M23.8,31.6c-0.4,0-0.7-0.1-1-0.4l-15.5-14c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.4,0.2-0.9,0.5-1.2l15.5-14 c0.6-0.6,1.6-0.5,2.2,0.1c0.6,0.6,0.5,1.6-0.1,2.2L10.6,16l14.2,12.9c0.6,0.6,0.7,1.6,0.1,2.2C24.6,31.4,24.2,31.6,23.8,31.6z"/></svg>';
$slider-arrow-right: '<svg viewBox="0 0 32 32" version="1.1" width="32" height="32" xmlns="http://www.w3.org/2000/svg"><path class="st0" d="M8.2,0.4c0.4,0,0.7,0.1,1,0.4l15.5,14c0.3,0.3,0.5,0.7,0.5,1.2c0,0.4-0.2,0.9-0.5,1.2l-15.5,14 c-0.6,0.6-1.6,0.5-2.2-0.1c-0.6-0.6-0.5-1.6,0.1-2.2L21.4,16L7.2,3.1C6.6,2.5,6.5,1.6,7.1,0.9C7.4,0.6,7.8,0.4,8.2,0.4z"/></svg>';

/*
 
 Buttons
 ---------------------------------------------------- */

$_opc-button-base-progress-scale : 0.8;
$_opc-button-base-border-radius : 0px;
$_opc-button-primary-link-color : $primary-button-color;
$_opc-button-primary-link-color-hover : darken($_opc-button-primary-link-color, 20%);
$_opc-button-secondary-link-color : $secondary-button-color;
$_opc-button-secondary-link-color-hover : darken($_opc-button-secondary-link-color, 20%);
$_opc-button-primary-border-radius : $_opc-button-base-border-radius;
$_opc-button-primary-color : rgba(#fff, 0.8);
$_opc-button-primary-color-hover : rgba(#fff, 1);
$_opc-button-primary-bg : $primary-button-color;
$_opc-button-primary-bg-hover : lighten($_opc-button-primary-bg, 10%);
$_opc-button-primary-progress-color : $progress-color;
$_opc-button-primary-success-color : $success-color;
$_opc-button-secondary-border-radius : $_opc-button-base-border-radius;
$_opc-button-secondary-color : $brand-color-2;
$_opc-button-secondary-color-hover : rgba(#000, 1);
$_opc-button-secondary-bg : $brand-color-2;
$_opc-button-secondary-bg-hover : lighten($secondary-button-color, 10%);
$_opc-button-secondary-progress-color : $progress-color;
$_opc-button-secondary-success-color : $success-color;
$_opc-button-loud-border-radius : $_opc-button-base-border-radius;
$_opc-button-loud-color : rgba(#000, 1);
$_opc-button-loud-color-hover : rgba(#000, 0.8);
$_opc-button-loud-bg : $loud-button-color;
$_opc-button-loud-bg-hover : lighten($loud-button-color, 10%);
$_opc-button-loud-progress-color : $progress-color;
$_opc-button-loud-success-color : $success-color;
$_opc-button-font-size-s : $_opc-font-size-s;
$_opc-button-font-size-m : $_opc-font-size-m;
$_opc-button-font-size-l : $_opc-font-size-l;
$_opc-button-font-size-xl : $_opc-font-size-xl;

/*
 
 Forms
 ---------------------------------------------------- */

$_opc-form-field-font-family : sans-serif;
$_opc-form-field-font-size : inherit;
$_opc-form-field-margin : 2rem;
$_opc-form-field-border : 2px solid $brand-color-2;
$_opc-form-field-border-hover : 2px solid #000;
$_opc-form-field-border-focus : 2px solid #000;
$_opc-form-field-border-invalid : 2px solid $error-color;
$_opc-form-field-border-valid : 2px solid $success-color;
$_opc-form-field-color : $brand-color-2;
$_opc-form-field-color-invalid : $error-color;
$_opc-form-field-color-message-invalid: $error-color;
$_opc-form-field-color-valid : $success-color;
$_opc-form-field-color-message-valid : $success-color;
$_opc-form-field-placeholder-color : #ccc;
$_opc-form-field-bg : #fff;
$_opc-form-field-bg-hover : #fff; 
$_opc-form-field-bg-focus : #fff;
$_opc-form-field-label-color : $brand-color-2;
$_opc-form-field-label-font-weight : 600;
$_opc-form-field-label-font-family : inherit;
$_opc-form-field-msg-font-size : 1.2rem;
$_opc-form-field-font-size-s : $_opc-font-size-s;
$_opc-form-field-font-size-m : $_opc-font-size-m;
$_opc-form-field-font-size-l : $_opc-font-size-l;
$_opc-form-field-font-size-xl : $_opc-font-size-xl;

$_opc-radio-width : 2rem;
$_opc-radio-height : 2rem;
$_opc-radio-border : 1px solid #ccc;
$_opc-radio-border-hover : 1px solid #bbb;
$_opc-radio-border-focus : 1px solid #aaa;
$_opc-radio-border-checked : 1px solid #ccc;
$_opc-radio-border-radius : 2rem;
$_opc-radio-color : #000;
$_opc-radio-bg : #fff;
$_opc-radio-bg-hover : #fff;
$_opc-radio-bg-focus : #fff;
$_opc-radio-bg-checked : #fff;
$_opc-radio-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-radio-icon-size : 1.6rem;
$_opc-radio-label-font-size : 1.4rem;

$_opc-checkbox-width : 2rem;
$_opc-checkbox-height : 2rem;
$_opc-checkbox-border : 1px solid #ccc;
$_opc-checkbox-border-hover : 1px solid #bbb;
$_opc-checkbox-border-focus : 1px solid #aaa;
$_opc-checkbox-border-checked : 1px solid #ccc;
$_opc-checkbox-border-radius : 0.5rem;
$_opc-checkbox-color : #000;
$_opc-checkbox-bg : #fff;
$_opc-checkbox-bg-hover : #fff;
$_opc-checkbox-bg-focus : #fff;
$_opc-checkbox-bg-checked : #fff;
$_opc-checkbox-icon : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-checked : inline-svg($op-icon-check-svg, '#333');
$_opc-checkbox-icon-size : 1.6rem;
$_opc-checkbox-label-font-size : 1.4rem;

$_opc-select-icon: inline-svg($op-icon-angle-down-svg, '$brand-color-2');

/*
 
 Links
 ---------------------------------------------------- */

$_opc-link-color: $brand-color-1;
$_opc-link-color-visited: $_opc-link-color;
$_opc-link-color-hover: lighten($_opc-link-color, 30%);
$_opc-link-color-active: lighten($_opc-link-color, 10%);
$_opc-link-underline-animation-duration: 0.2s;



h1,h2,h3,h4,h5,h6{
    color: $brand-color-2;
}

.search-open .opc-search[data-op-type="fullscreen"]{
    z-index: 99999!important;
}

.breadcrumb-navigation{
    font-size: 1.8rem;
}

.opc-segment-navigation[data-op-type="pagination"]{
    .opc-button{
        background-color: $brand-color-2;
        color: $brand-color-1;
        &:focus{
            background-color: $brand-color-2;
            color: $brand-color-1;
        }
    }

}
:root {
    --clr-primary: #{$brand-color-1};
}
