/*###############################################

@Title: Navigation
@Description:
This file contains the navigation styles

###############################################*/

$_opc-navigation-padding-level-1: 1.5rem 2rem !default;
$_opc-navigation-padding-level-2: 0.5rem 1rem !default;
$_opc-navigation-padding-level-3: 0.5rem 1rem !default;
$_opc-navigation-level-2-top: 110% !default;
$_opc-navigation-bg: transparent;
$_opc-navigation-bg-hover: transparent;
$_opc-navigation-bg-active: $brand-color-1 !default;
$_opc-navigation-color: $white;
$_opc-navigation-color-hover: $white;
$_opc-navigation-color-active: $white;
$_opc-navigation-submenu-bg : transparent;
$_opc-navigation-submenu-bg-hover: transparent;
$_opc-navigation-submenu-bg-active: transparent;
$_opc-navigation-megamenu-bg : transparent;
$_opc-navigation-megamenu-headline-color: $brand-color-1;


.opc-navigation {
    @include clearfix();
    /* ---------------------------------------------------- */
    // global nav reset styles
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        @include clearfix();
        li {
            a {
                display: block;
                text-decoration: none;
                color: unset;
                &:hover {
                    color: unset;
                }
            }
        }
    }
    /* ---------------------------------------------------- */
    // global nav level 1
    .navigation-level-1 {
        >li {
            >a {}
            &:hover {
                // style a tag of hovered element
                >a {}
                // show nav level 2 on hover if available
                >.navigation-level-2 {}
            }
        }
    }
    /* ---------------------------------------------------- */
    // global nav level 2
    .navigation-level-2 {
        >li {
            >a {
                white-space: nowrap;
            }
            &:hover {
                >a {}
            }
        }
    }
    .navigation-level-3 {}

    /*
     Simple Menu
     ---------------------------------------------------- */
    &[data-op-navtype="simple"] {
        a {
            &::before {
                display: none;
            }
        } // nav level 1
        .navigation-level-1 {
            >li {
                >a {
                    white-space: nowrap;
                    padding: 0;
                }
                &:hover {
                    // style a tag of hovered element
                    >a {
                        background-color: transparent;
                    }
                }
            }
        } // nav level 2
        .navigation-level-2 {
            >li {
                >a {
                    white-space: nowrap;
                    padding: 0;
                }
                &:hover {
                    >a {
                        background: transparent;
                    }
                }
            }
        }
        .navigation-level-3 {
            >li {
                >a {
                    white-space: nowrap;
                    padding: 0;
                }
                &:hover {
                    >a {
                        background: transparent;
                    }
                }
            }
        }
    }

}

.content{
    // .section-wrapper{
    //     .section-inner{
            .content-main-area{
                display: flex;
                flex-wrap: wrap;
            }
            nav.catalogmenu-wrapper{
                display: none;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                background: $brand-color-2;
                z-index: 99999;
                width: 31.4rem;
                padding: 2rem;
                transition: transform .3s ease-in;
                .menu-flag{
                    width: 7.3rem;
                    height: 7.3rem;
                    background: $brand-color-2;
                    position: absolute;
                    right: 1px; // sonst blitzt der BG durch
                    top: 30rem;
                    transform: translate(100%, 0);
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    svg{
                      width: 100%;
                      color: #e0ab12;
                      font-size: 4.5rem;
                    }
                }
                &.is-open{
                    transform: translateX(0);
                }
                a{
                    color: $white;
                    white-space: nowrap;
                }
                @include mq($min-width: map-get($breakpoints, xxl)) {
                    display: block;
                    padding: 0;
                    position: static;
                    background-color: transparent;
                    margin-left: -31.4rem;
                    .menu-flag{
                        display: none;
                    }
                    a{
                        color: $black;
                    }
                }

                .catalogmenu-inner{
                    h2{
                        text-transform: uppercase;
                        font-size: 1.8rem;
                        margin-bottom: 3rem;
                        font-family: $_opc-base-font-family;
                        font-weight: 700;
                        color: $white;
                        @include mq($min-width: map-get($breakpoints, xxl)) {
                            color: $black;
                        }
                    }
                    .opc-navigation[data-op-navtype="aside-click"],
                    .opc-navigation[data-op-navtype="simple"]{
                        font-size: 1.8rem;
                        line-height: 2.8rem;
                        ul.navigation-level-1{
                            >li{
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                margin-bottom: 0;
                                >a{
                                    text-transform: uppercase;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    padding-left: 0;
                                    display: inline-block;
                                }
                                .navigation-toggler{
                                    display: block;
                                    width: 1.6rem;
                                    height: 2.8rem;
                                    background-image: inline-svg($op-icon-angle-down-svg, '#000');
                                    background-position: center;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    cursor: pointer;
                                }
                                &.is-open{
                                    >a{
                                        font-weight: normal;
                                        color: $black;
                                    }
                                }
                                &.is-active,
                                &.is-active-parent{
                                    >a{
                                        font-weight: 700;
                                        color: $black;
                                    }
                                }
                                ul.navigation-level-2{
                                    display: none;
                                    width: 100%;
                                    >li{
                                        position: relative;
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin-bottom: 0;
                                        >a{
                                            padding-top: 0;
                                            padding-bottom: 0;
                                            padding-left: 0;
                                            display: inline-block;
                                            &:hover{
                                                font-weight: 700;
                                            }
                                        }
                                        &.is-open{
                                            >a{
                                                font-weight: normal;
                                                color: $black;
                                            }
                                        }
                                        ul.navigation-level-3{
                                            display: none;
                                            >li{
                                                position: relative;
                                                margin-bottom: 0;
                                                >a{
                                                    padding-left: 0;
                                                }
                                                &.is-open{
                                                    >a{
                                                        font-weight: normal;
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                        &.is-active,
                                        &.is-active-parent{
                                            >a{
                                                font-weight: 700;
                                                color: $brand-color-2;
                                            }
                                        }
                                    }
                                }
                                &.is-active,
                                &.is-active-parent{
                                    >a{
                                        font-weight: 700;
                                    }
                                }
                                &.is-open{
                                    margin-bottom: 2.8rem;
                                    >.navigation-toggler{
                                        transform: rotate(180deg);
                                    }
                                    ul.navigation-level-2{
                                        display: block;
                                        >li{
                                            &.is-open{
                                                >.navigation-toggler{
                                                    transform: rotate(180deg);
                                                }
                                                ul.navigation-level-3{
                                                    display: block;
                                                    width: 100%;
                                                    >li{
                                                        position: relative;
                                                        display: flex;
                                                        flex-wrap: wrap;
                                                        align-items: center;
                                                        padding-left: 3.5rem;
                                                        >a{
                                                            padding-top: 0;
                                                            padding-bottom: 0;
                                                            padding-left: 0;
                                                            display: inline-block;
                                                            &:hover{
                                                                font-weight: 700;
                                                            }
                                                        }
                                                        &.is-active,
                                                        &.is-active-parent{
                                                            >a{
                                                                font-weight: 700;
                                                                color: $white;
                                                                @include mq($min-width: map-get($breakpoints, xxl)) {
                                                                    color: $brand-color-2;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }

                    // .opc-navigation[data-op-navtype="aside-click"].detailpage,
                    // .opc-navigation[data-op-navtype="simple"].detailpage{
                    //     font-size: 1.8rem;
                    //     line-height: 2.8rem;
                    //     ul.navigation-level-2{
                    //         display: block;
                    //         >li{
                    //             position: relative;
                    //             >a{
                    //                 text-transform: uppercase;
                    //             }
                    //             ul.navigation-level-3{
                    //                 display: none;
                    //                 >li{
                    //                     position: relative;
                    //                     >a{
                    //                         &:hover{
                    //                             font-weight: 700;
                    //                         }
                    //                     }
                    //                     ul.navigation-level-4{
                    //                         display: none;
                    //                         >li{
                    //                             position: relative;
                    //                             >a{}
                    //                         }
                    //                     }
                    //                     &.is-active,
                    //                     &.is-active-parent{
                    //                         >a{
                    //                             font-weight: 700;
                    //                             color: $brand-color-2;
                    //                         }
                    //                         ul.navigation-level-4{
                    //                             display: block;
                    //                             >li{
                    //                                 position: relative;
                    //                                 padding-left: 3.5rem;
                    //                                 >a{
                    //                                     &:hover{
                    //                                         font-weight: 700;
                    //                                     }
                    //                                 }
                    //                                 &.is-active,
                    //                                 &.is-active-parent{
                    //                                     >a{
                    //                                         font-weight: 700;
                    //                                         color: $brand-color-2;
                    //                                     }
                    //                                 }
                    //                             }
                    //                         }
                    //                     }
                    //                     &.has-children{
                    //                         >a{
                    //                             padding-right: 3rem;
                    //                             display: inline-block;
                    //                             &::after{
                    //                                 content: '';
                    //                                 width: 1.6rem;
                    //                                 height: 100%;
                    //                                 display: inline-block;
                    //                                 background-image: inline-svg($op-icon-angle-down-svg, '#000');
                    //                                 background-position: center;
                    //                                 background-size: 100%;
                    //                                 background-repeat: no-repeat;
                    //                                 opacity: 1;
                    //                                 position: absolute;
                    //                                 right: 0;
                    //                             }
                    //                         }
                    //                     }
                    //                 }
                    //             }
                    //             &.is-active,
                    //             &.is-active-parent{
                    //                 >a{
                    //                     font-weight: 700;
                    //                 }
                    //                 ul.navigation-level-3{
                    //                     display: block;
                    //                 }
                    //                 margin-bottom: 2.8rem;
                    //             }
                    //         }
                    //     }
                    // }

                    .opc-navigation[data-op-navtype="aside-click"].detailpage{
                        font-size: 1.8rem;
                        line-height: 2.8rem;
                        ul.navigation-level-2{
                            display: block;
                            >li{
                                position: relative;
                                display: flex;
                                flex-wrap: wrap;
                                align-items: center;
                                margin-bottom: 0;
                                >a{
                                    text-transform: uppercase;
                                    padding-top: 0;
                                    padding-bottom: 0;
                                    padding-left: 0;
                                    display: inline-block;
                                }
                                .navigation-toggler{
                                    display: block;
                                    width: 1.6rem;
                                    height: 2.8rem;
                                    background-image: inline-svg($op-icon-angle-down-svg, '#000');
                                    background-position: center;
                                    background-size: 100%;
                                    background-repeat: no-repeat;
                                    cursor: pointer;
                                }
                                &.is-open{
                                    >a{
                                        font-weight: normal;
                                        color: $black;
                                    }
                                }
                                &.is-active,
                                &.is-active-parent{
                                    >a{
                                        font-weight: 700;
                                        color: $black;
                                    }
                                }
                                ul.navigation-level-3{
                                    display: none;
                                    width: 100%;
                                    >li{
                                        position: relative;
                                        display: flex;
                                        flex-wrap: wrap;
                                        margin-bottom: 0;
                                        >a{
                                            padding-top: 0;
                                            padding-bottom: 0;
                                            padding-left: 0;
                                            display: inline-block;
                                            &:hover{
                                                font-weight: 700;
                                            }
                                        }
                                        &.is-open{
                                            >a{
                                                font-weight: normal;
                                                color: $black;
                                            }
                                        }
                                        ul.navigation-level-4{
                                            display: none;
                                            >li{
                                                position: relative;
                                                margin-bottom: 0;
                                                >a{
                                                    padding-left: 0;
                                                }
                                                &.is-open{
                                                    >a{
                                                        font-weight: normal;
                                                        color: $black;
                                                    }
                                                }
                                            }
                                        }
                                        &.is-active,
                                        &.is-active-parent{
                                            >a{
                                                font-weight: 700;
                                                color: $brand-color-2;
                                            }
                                        }
                                    }
                                }
                                &.is-active,
                                &.is-active-parent{
                                    >a{
                                        font-weight: 700;
                                    }
                                }
                                &.is-active,
                                &.is-active-parent,
                                &.is-open{
                                    margin-bottom: 2.8rem;
                                    >.navigation-toggler{
                                        transform: rotate(180deg);
                                    }
                                    ul.navigation-level-3{
                                        display: block;
                                        >li{
                                            &.is-active,
                                            &.is-active-parent,
                                            &.is-open{
                                                >.navigation-toggler{
                                                    transform: rotate(180deg);
                                                }
                                                ul.navigation-level-4{
                                                    display: block;
                                                    width: 100%;
                                                    >li{
                                                        position: relative;
                                                        display: flex;
                                                        flex-wrap: wrap;
                                                        align-items: center;
                                                        padding-left: 3.5rem;
                                                        >a{
                                                            padding-top: 0;
                                                            padding-bottom: 0;
                                                            padding-left: 0;
                                                            display: inline-block;
                                                            &:hover{
                                                                font-weight: 700;
                                                            }
                                                        }
                                                        &.is-active,
                                                        &.is-active-parent{
                                                            >a{
                                                                font-weight: 700;
                                                                color: $white;
                                                                @include mq($min-width: map-get($breakpoints, xxl)) {
                                                                    color: $brand-color-2;
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .result-view-wrapper{
                flex-basis: 100%;
                width: 100%;
            }
    //     }
    // }
}