/*###############################################

@Title: Typography
@Description:
This file contains the typography styles

###############################################*/


h1,
.h1 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h1;
    font-style: italic;
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h1, $_opc-font-size-h1-max);
    }
}

h2,
.h2 {
    margin: 0 0 0.5em;
    font-family: $_opc-headline-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h2;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h2, $_opc-font-size-h2-max);
    }
    .notifications-modal &{
        font-size: 3.6rem;
        text-transform: uppercase;
        font-family: $_opc-base-font-family;
        font-weight: 700;
        font-style: normal;
        word-break: break-word;
    }
}

h3,
.h3 {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h3;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h3, $_opc-font-size-h3-max);
    }
}

h4,
.h4 {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h4;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h4, $_opc-font-size-h4-max);
    }
}

h5,
.h5 {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h5;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h5, $_opc-font-size-h5-max);
    }
}

h6,
.h6 {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-headline-font-weight;
    line-height: $_opc-headline-line-height;
    font-size: $_opc-font-size-h6;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-h6, $_opc-font-size-h6-max);
    }
}

.lead {
    margin: 0 0 0.5em;
    font-family: $_opc-lead-font-family;
    font-weight: $_opc-lead-font-weight;
    line-height: $_opc-lead-line-height;
    font-size: $_opc-font-size-lead;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-lead, $_opc-font-size-lead-max);
    }
}

p,
.copy,
.text {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-base-font-weight;
    line-height: $_opc-base-line-height;
    font-size: $_opc-font-size-copy;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-copy, $_opc-font-size-copy-max);
    }
}

small,
.small {
    margin: 0 0 0.5em;
    font-family: $_opc-base-font-family;
    font-weight: $_opc-base-font-weight;
    line-height: $_opc-base-line-height;
    font-size: $_opc-font-size-small;
    
    @if $_opc-fluid-typo == 'true' {
        @include fluid-type($_opc-font-min-width, $_opc-font-max-width, $_opc-font-size-small, $_opc-font-size-small-max);
    }
}

.opc-mini-label {
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #000;
    opacity: 0.4;
    margin-bottom: 0.5em;
    display: block;
}