.opc-basket-info {
    >.opc-button {
        font-size: $_opc-basket-button-font-size;
        border: none;
        background: none;
        margin-left: 0.5rem;
        cursor: pointer;
        position: relative;
        padding: 0 0.5rem;
        svg {
            font-size: 4rem;
        }
        &:hover {}
        .basket-info-count {
            transform: none;
            margin-left: .5rem;
            &:empty {
                display: none;
            }
        }
    } 
    >.mini-basket{
        color: $black;
        .mini-basket-head{

        }
        .mini-basket-body{
            li.basket-item{
                .basket-item-actions{
                    .opc-button{
                        svg{
                            display: block;
                        }
                    }
                }
            }
        }
    }

}
