.opc-article-list {
    &[data-op-tile-col="5"] {
        >li {
            @include mq($min-width: map-get($breakpoints, xs)) {
                flex-basis: span(6);
                max-width: span(6);
            }
            @include mq($min-width: map-get($breakpoints, m)) {
                flex-basis: span(4);
                max-width: span(4);
            }
            @include mq($min-width: map-get($breakpoints, l)) {
                flex-basis: span(3);
                max-width: span(3);
            }
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: 20%;
                max-width: 20%;
            }
        }
    }
    &[data-op-article-list-type="list"]{
        >li{
            flex-basis: 100%;
            max-width: 100%;
            border-bottom: 1pt solid $brand-color-2;
        }
        .article-list-item {
            text-align: left;
        }
        .article-list-item-image{
            flex-basis: 12rem;
            @include mq($min-width: map-get($breakpoints, xl)) {
                flex-basis: 24rem;
            }
            margin-right: 0;
            padding-right: 4rem;
            img{
                max-width: 100%;
            }
            .article-list-item-labels{
                width: 6rem;
                flex-wrap: wrap;
                // margin-right: 1rem;
                top: -1.5rem;
                bottom: -1.5rem;
                align-items: flex-start;
            }
        }
        .article-list-item-details{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .article-list-item-details-col{
                flex-basis: 20%;
                font-size: 1.8rem;
                &:nth-child(1){
                    flex-basis: 40%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    .article-list-item-artno{
                        order: 2;
                        font-size: 1.8rem;
                    }
                    .article-list-item-title{
                        text-transform: none;
                        font-size: 1.8rem;
                    }
                }
                &:nth-child(3){
                    display: block;
                }
                .opc-stock{
                    display: block;
                    font-size: 1.8rem;
                    font-weight: 700;
                    svg{
                        display: none
                    }
                }
            }
        }
        .article-list-item-actions{
            align-items: center;
            .opc-favorite-button{
                order: 2;
            }
        }
    }
}
.article-list-item {
    border: none!important;
    display: flex;
    flex-direction: column;
    height: 100%;
    &:hover{
        border: none!important;
        box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
    text-align: center;
    .article-list-item-details {
        .article-list-item-details-col{
            &:nth-child(3){
                // display: none;
                .price-wrapper{
                    margin-bottom: 0;
                }
            }
        }
        .opc-price {
            min-height: 2.4rem;
            margin-bottom: 0.5rem;
        }
        .opc-stock{
            display: none;
        }
        .article-list-item-title {
            font-family: $_opc-base-font-family;
            font-size: 1.6rem;
            font-weight: 700;
            margin-bottom: 0.9rem;
            min-height: 4.0rem;
            max-height: 8.0rem;
            overflow: hidden;
            // text-transform: uppercase;
            // &.oversize{
            //     a{
            //         font-size: 12px;
            //         line-height: 1.1;
            //         display: block;
            //         font-weight: 900;
            //     }
            // }
        }
        .article-list-item-artno{
            font-size: 1rem;
            margin-bottom: .5rem;
        }
        .article-list-item-description {
            //height: 3.4rem;
            overflow: hidden;
            
            p,
            .is-product-msg {
                height: 4.4rem;
                font-size: 1.4rem;
                overflow: hidden;
                margin-bottom: 0;
                padding: 0.5rem 0;
                line-height: 1.3;
                &:empty {
                    display: none;
                }
            }
            .is-product-msg {
                background: #eee;
                display: block;
                padding: 0.5rem 1rem;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                border-radius: 1rem;
                font-size: 1.2rem;
                max-width: 60rem;
            }
        }   
    }
    .article-list-item-image{
        height: 15rem;
        margin-bottom: 1rem;
        position: relative;
        img{
            max-width: 18rem;
            max-height: 100%;
        }
        .article-list-item-labels{
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            width: 3rem;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            >img{
                max-width: 30px;
            }
        }
    }
    // styling action buttons
    .article-list-item-actions {
        overflow: visible;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        color: #000;
        margin-top: auto;

        .details-button {
            margin-left: auto;
            margin-bottom: 0.5rem;
        }

        .opc-favorite-button { 
            background-color: #fff;
            order: 2;
            &:hover,
            &:focus {
                // box-shadow: 1px 3px 3px #ccc;
                background-color: transparent;
            }
        }
        .opc-compare-button { 
            background-color: #fff;
            margin-left: 1rem;
            &:hover,
            &:focus {
                // box-shadow: 1px 3px 3px #ccc;
                background-color: transparent;
            }
        }
        .opc-basket-button {
            width: 4rem;
            padding: 0;
            margin-left: 0;
            position: relative;
            padding-right: 8px;
            order: 1;
            svg {
                margin-right: 0;
            }
            span {
                display: none;
            }
            &:hover,
            &:focus {
                // box-shadow: 1px 3px 3px #ccc;
                background-color: transparent;
            }
            &::after{
                content: " ";
                width: 1pt;
                height: 2.2rem;
                background-color: $brand-color-1;
                position: absolute;
                right: 0;
            }
        }
        .details-button {
            width: auto;
            @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
        }
    }

    [data-op-article-list-type="tile"] & {
        .opc-stock{
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: block;
            svg,
            .stock-label,
            .stock-value{
                display: none;
            }
            width: 12px;
            height: 12px;
            border-radius: 999px;
            &[data-op-status="on-stock"] {
                background-color: $stock-on-stock;
            }
            &[data-op-status="not-available"],
            &[data-op-status="out-stock"] {
                background-color: $stock-out-stock;
            }
            &[data-op-status="call-stock"] {
                background-color: $stock-call-stock;
            }
            &[data-op-status="less-stock"] {
                background-color: $stock-less-stock;
            }
        }
    }

    [data-op-article-list-type="list"] & {
        height: auto;
        flex-direction: row;
        align-items: center;
        &:hover{
            box-shadow: none;
        }
        .article-list-item-details {
            .article-list-item-title {
                font-size: 1.8rem;
                font-weight: 600;
                margin-bottom: 0.5rem;
                height: auto;
                overflow: hidden;
            }
        }
        .article-list-item-actions {
            overflow: visible;
            display: flex;
            align-items: flex-start;
            color: #000;
            .details-button {
                margin-left: auto;
                margin-bottom: 0.5rem;
            }
            .opc-favorite-button { 
                background-color: #fff;
                margin-left: 1rem;
                margin-bottom: 0;
                &:hover,
                &:focus {
                    box-shadow: none;
                }
                svg{
                    font-size: 4rem;
                }
            }
            .opc-more-button {
                margin-left: 0;
                margin-right: 0.5rem;
            }
            .opc-compare-button { 
                background-color: #fff;
                margin-left: 0;
                margin-right: 0.5rem;
                &:hover,
                &:focus {
                    box-shadow: none;
                }
            }
            .opc-basket-button {
                width: 5rem;
                padding-right: 1.5rem;
                justify-content: center;
                svg {
                    margin-right: 0;
                    font-size: 4rem;
                }
                span {
                    display: none;
                }
                @include mq($min-width: map-get($breakpoints, s)) {
                }
                &:hover,
                &:focus {
                    box-shadow: none;
                }
                &::after{
                    height: 3.2rem;
                }
            }
            .details-button {
                width: 100%;
                @include mq($min-width: map-get($breakpoints, m)) { width: auto; }
            }
        }
    }
}


.article-list-item-flags{
    left: 0!important;
    .flags-wrapper{
        .flags-wrapper-inner{
            .flag{
                color: $white;
                font-size: 4rem;
                svg{
                    display: none;
                }
                span.text{
                    display: block;
                    padding: .3rem 1rem;
                    font-size: 1.2rem;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                }
                &1{
                    span.text{
                        background-color: rgba(#E28E8E,.95);
                    }
                }
                &2{
                    span.text{
                        background-color: rgba(#7BC094,.95);
                    }
                }
                &3{
                    span.text{
                        background-color: rgba(#67A1A5,.95);
                    }
                }
                &4{
                    span.text{
                        background-color: rgba(#E8D25B,.95);
                    }
                }
            }
        }
    }
    [data-op-article-list-type="list"] &{
        top: 0!important;
        max-width: 12rem;
        .flags-wrapper{
            .flags-wrapper-inner{
                .flag{
                    svg{}
                    span.text{
                    }
                }
            }
        }
    }
}



.price-wrapper{
    margin-bottom: 2rem;
    .price-wrapper-inner{
        display: flex;
        flex-direction: column;
        .myPrice{
            // font-weight: 700;
            .price{
                
            }
            .currency{

            }
        }
        .vp1{
            text-decoration: line-through;
            opacity: .6;
        }
        .vp2{
            &.aktion{
                text-decoration: line-through;
                opacity: .6;
            }
        }
        .uvp{
            display: none;
        }
    }
}


.notifications-content{
    .modal-basket-button{
        .infos-wrapper{
            margin: 0 0 2rem;
        }
        div{
            .label{
                &::after{
                    content:":";
                    margin-right: 1rem;
                }
            }
            .value{
                font-weight: bold;
            }
        }
    }
}

#tabsPart1 {
    .opc-article-list[data-op-article-list-type="tile"] {
        .article-list-item-image img {
            max-width: -webkit-fill-available;
        }
    }
}