//Slider: Umbruch der Artikel-Liste verhindern (horizontales scrollen)
[data-op-slide="articles"],
[data-op-slide="categories"] {
    overflow:hidden !important; 
    flex-wrap: nowrap !important;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    > li {
        flex-shrink: 0 !important;
    }

}


.psp-slider-pending {
    .op-mediaslider-controls {
        display: none;
    }
}

.opc-carousel {
    margin-bottom: 0px;
}

.psp-slider {
    ul {

        margin: 0px;

        > li {
            padding-left: 0px;
            max-width: none !important;
            flex-shrink: 1;
            flex-basis: auto !important;
        }
    }

    .op-mediaslider-nav {
        display: none;
    }

    .op-mediaslider-controls {

        top: 151px;

        > button {

            &[data-controls="prev"],
            &[data-controls="next"] {
                width: auto;
                height: auto;
                padding: 15px;
                background: transparent;

                &:hover,
                &:active,
                &:focus {
                    background: transparent;
                }

                &::before {
                    width: 21px;
                    height: 21px;
                    background-size: 20px;
                }
                &::after {
                    display: none;
                }
            }

            &[data-controls="prev"]{

                &::before {
                    background-image : inline-svg($psp-icon-slider-navigation-left-svg, $brand-color-1);
                }
            }

            &[data-controls="next"] {
                &::before {
                    background-image : inline-svg($psp-icon-slider-navigation-right-svg, $brand-color-1);
                }
            }
        }
    }

    &[data-op-slider-type="categories"] {
        .op-mediaslider-controls {

            top: 118px;

            @include mq($min-width: map-get($breakpoints, m)) {
                top: 130px;
            }

            @include mq($min-width: map-get($breakpoints, l)) {
                top: 205px;
            }
        }
    }
}



.controls{
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    display: flex;
    justify-content: space-between;
    top: 50%;
    transform: translateY(2rem);
    z-index: 2;
    button{
        width: 4rem;
        height: 4rem;
        background-color: transparent;
        border: none;
        padding-right: 0;
        cursor: pointer;
        &::before {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background-color: transparent;
            background-size: cover;
            background-repeat: no-repeat;
        }
    }
    .prevButton{
        &::before {
            background-image : inline-svg($slider-arrow-left, $brand-color-2);
        }
    }
    .nextButton{
        &::before {
            background-image : inline-svg($slider-arrow-right, $brand-color-2);
        }
    }
}