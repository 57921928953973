

.opc-search {
	
	position: relative;
    width: 100%;
    position: fixed;
	top: 0; 
	left: 0; 
	right: 0;
	bottom: 0;   
	z-index: 20000;
	visibility: hidden;
	opacity: 0;
	z-index: -100;
    border: none;
    &.is-open {
		visibility: visible;
		opacity: 1;
		z-index: 20000;
	}
	
	.search-toggle {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 3;
    	display: none;
    } 

    .opc-search-bg {
		background: rgba(#fff, 0.98);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1; 
		display: block;
	}
	.opc-search-content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 2;
		padding: 2rem;
		
	}
    

    @include mq($min-width: map-get($breakpoints, m)) {
		position: relative;
		width: 100%;
		top: auto;
		left: auto;
		right: auto;
		bottom: auto;
		background: none;
		visibility: visible;
		opacity: 1;
		z-index: 99;
		// z-index: 1;
		
		
		.opc-search-bg,
		.opc-search-toggle {
			display: none;
			
		}
		
		.opc-search-content {
			position: relative;
			padding: 0;
		}
	}


	.opc-search-content {
        .opc-button[data-op-action="close"] {
            @include mq($min-width: map-get($breakpoints, m)) {
                display: none;
            }
        }

		.opc-search-query {
            border: none;
            display: flex;
            padding: 0.5rem 0;
            .search-query {
                border: 2px solid $brand-color-2;
                @include mq($min-width: map-get($breakpoints, m)) {
                    border-right: none;
                }
                height: 3.4rem;
                padding-left: 1rem;
                padding-right: 1rem;
                &:focus {
                    outline:none;
                }
            }
            .search-filter {
                border: none;
                border-left: 1px solid #ccc;
                border-right: 1px solid #ccc;
                padding: 0 1rem;
                height: 3.4rem;
                &:focus {
                    outline:none;
                }
            }
            .search-btn {
                background: $white;
                border: 2px solid $brand-color-2;
                border-left: none;
                height: 3.4rem;
                width: 4rem; 
                flex-shrink: 0;
                color: $brand-color-2;
            }
		}
		
		.opc-search-results {
			display: none;
            padding: 0;
            position: relative;
            top: 0;

            @include mq($min-width: map-get($breakpoints, m)) {
               position: absolute;
               top: 100%;
            } 

            .opc-directsearch-message {
                height: 4rem;
                position: relative;
                z-index: 2;
                background: #eee;
                margin: 0.5rem;
                display: flex; 
                align-items: center;
                justify-content: center;
            }

		} 
		
		
	}
	
	

	input {
		width: 100%;
	}
}



.column-section-list {
    list-style: none;
    margin: 0; 
    padding: 0;   

    li {
        border-bottom: 1px solid #eee;
        a {
            display: block; 
            padding: 1.5rem 0;  

            &:hover { 
                background: #eee;
            }

            > span {
                display: block;
            }
             
 
            
        }
    }

    &[data-op-type="article-image"] {
        .list-item-image {
            width: 8rem;
            margin-right: 1rem;
        }
        .list-item-info {
            width: calc(100% - 9rem);
        }
        .list-item-description {
            max-width: 100%;
        }


        a {
            display: flex;
            width: 100%;
            padding: 1rem 0;

            .image {
                //background: #ccc;
                margin-right: 1rem;
                display: flex;
                align-items: center;
                justify-content: center; 
            }
            .info {
                
                .name {
                    display: block;
                    font-weight: 600; 
                    margin-bottom: 0.2em;  
                }
                .additional-name {
                    display: block;
                    font-size: 1.2rem; 
                    opacity: 0.8;
                    margin-bottom: 0.2em;
                } 
                .description {
                    display: block;
                }
                .price {

                }



            }


        }
        



    }

    &[data-op-type="simple"] {
        a {
            position: relative;
        }
        .CatStruct-Name {
             
        }
        .CatStruct-Name ~ .Amount {display:none;}
        .CatStruct-Number {display:none;}
    }

    .moreItems {
        a {
            padding: 1rem;
            font-weight: bold;
            
            span {
                margin: 0;
                font-size: 1.2rem;
            }
        }
    }
} 



.opc-directsearch-suggest{
    border-radius: .4rem;
    background-color: $white;
    @include mq($min-width: map-get($breakpoints, m)) {
        box-shadow: 0 .5rem 1rem rgba(0,0,0,.35);
        padding: 0 2rem;
    }
    .directsearch-suggest-column{
        .directsearch-suggest-column-section{
            .column-section-title{
                // text-transform: none;
                color: $brand-color-2;
                font-size: 2rem;
                font-weight: 700;
                opacity: 1;
            }
            .column-section-list{
                .moreItems{
                    a{
                        justify-content: center;
                        span{
                            font-size: 1.4rem;
                            font-weight: 300;
                        }
                    }
                }
            }
            &.no1{
                ul.column-section-list{
                    li{
                        a{
                            .list-item-image{
                                mix-blend-mode: multiply;
                            }
                            .list-item-info{
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                font-weight: 300;
                                .list-item-name{
                                    font-size: 1.8rem;
                                    font-weight: 700;
                                    text-transform: uppercase; 
                                }
                                .list-item-description,
                                .list-item-price {
                                    display: none;
                                }
                            }
                            &:hover{
                                background: transparent;
                                color: $brand-color-1;
                            }
                        }
                    }
                }
            }
            &.no2{
                ul.column-section-list{
                    li{
                        a{
                            font-weight: 300;
                            .CatStruct-Name{
                                font-size: 1.8rem;
                                font-weight: 400;
                                text-transform: uppercase;
                            }
                            &:hover{
                                background: transparent;
                                color: $brand-color-1;
                            }
                        }
                    }
                }
            }
        }
    }
}