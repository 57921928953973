.opc-filter-set {
    .filter-set-list {
        .filter-set-list-item {
            &:first-child{
                flex-basis: 0!important;
                width: 0!important;
                padding: 0!important;
                #CF1{
                    display: none;
                }
                .logged-in &{
                    flex-basis: initial;
                    width: initial;
                    padding: initial;
                    #CF1{
                        display: block;
                    }
                }
            }
        }
    }
    &[data-op-layout="horizontal"] {
        .filter-set-list {
            .filter-set-list-item {
                @include mq($min-width: map-get($breakpoints, s)) {
                    flex-basis: span(6);
                    width: span(6);
                    max-width: span(6);
                }
                @include mq($min-width: map-get($breakpoints, m)) {
                    flex-basis: calc(100% / 3);
                    width: calc(100% / 3);
                    max-width: calc(100% / 3);
                }
                &:first-child{
                    .logged-in &{
                        padding: 0 15px!important;
                        flex-basis: 100%!important;
                        width: 100%!important;
                        max-width: 100%!important;
                        @include mq($min-width: map-get($breakpoints, s)) {
                            flex-basis: span(6)!important;
                            width: span(6)!important;
                            max-width: span(6)!important;
                        }
                        @include mq($min-width: map-get($breakpoints, m)) {
                            flex-basis: calc(100% / 3)!important;
                            width: calc(100% / 3)!important;
                            max-width: calc(100% / 3)!important;
                        }
                        #CF1{
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

.opc-filter{
    .filter-head {
        border: 2px solid $brand-color-2!important;
        .filter-head-title{
            color: $brand-color-2;
        }
        svg{
            color: $brand-color-2;
        }
    }
    .filter-body {
        border: 2px solid $brand-color-2!important;
        .filter-body-search {
            input {
            }
        }
    }
    &[data-op-style="dropdown"]{
    }
}