.opc-button {
  &.opc-basket-button{
    border: none;
    box-shadow: none;
    opacity: 1;
    background-color: transparent;
    svg{
      width: 4rem;
      color: $brand-color-1;
      font-size: 3rem;
    }
    &:hover{
      border: none!important;
      box-shadow: none!important;
      background-color: transparent!important;
    }
  }
  &[data-op-style="basket"]{
    color: $brand-color-2;
    font-size: 1.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    &:hover{
      color: $brand-color-1;
      background-color: $brand-color-2;
    }
    &:focus{
      box-shadow: none;
    }
    svg{
      font-size: 2.8rem;
    }
  }

  &[data-op-style="loud"]{
    font-weight: 700;
    // &:hover{
    //   color: $brand-color-1;
    //   background-color: $brand-color-2;
    // }
  }

  &[data-op-style="secondary-lined"] {
    background: transparent;
    border-color: $_opc-button-secondary-bg;
    color: $_opc-button-secondary-color;
    &:hover, &:focus {
      color: $_opc-button-secondary-color-hover;
      border-color: $_opc-button-secondary-bg-hover;
    }
  }
  &[data-op-style="secondary"] {
    background: transparent;
    border-color: $_opc-button-secondary-bg;
    color: $_opc-button-secondary-color;
    &:hover, &:focus {
      color: $_opc-button-secondary-color-hover;
      border-color: $_opc-button-secondary-bg-hover;
    }
  }
}

.opc-favorite-button{
  &[data-op-size="normal"][data-op-type="icon"]{
    border: none;
    box-shadow: none;
    opacity: 1;
    svg{
      width: 4rem;
      color: $brand-color-1;
      font-size: 3rem;
    }
    &:hover{
      border: none!important;
      box-shadow: none!important;
      background-color: transparent!important;
    }
    &:focus{
      box-shadow: none;
    }
  }
  &[data-op-style="loud"]{
    color: $brand-color-2;
    font-size: 1.8rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    opacity: 1;
    font-weight: normal;
    &:hover{
      color: $brand-color-1;
      background-color: $brand-color-2;
    }
    svg{
      font-size: 2.8rem;
    }
  }
}

.article-list-menu .article-list-layout{
  .opc-catstruct-list-layout{
    .opc-button{
      opacity: 1;
      svg{
        color: $brand-color-2;
        font-size: 3.2rem;
      }
      &.is-active{
        svg{
          color: $brand-color-1;
        }
      }
    }
  }
}