.side-flags-right{
  position: fixed;
  width: 31.4rem;
  right: -31.4rem;
  top: 0;
  bottom: 0;
  z-index: 9999;
  display: none;
  @include mq($min-width: map-get($breakpoints, m)) {
    display: block;
  }
  .flag-contact{
    background-color: $brand-color-2;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all .2s ease-in;
    &.is-open{
      transform: translateX(-31.4rem);
      z-index: 2;
    }
    .flag-icon{
      margin-top: 30rem;
      transform: translateX(-4.3rem);
      width: 5.3rem;
      @include mq($min-width: map-get($breakpoints, xxl)) {
        transform: translateX(-7.3rem);
        width: 8.3rem;
      }
      background-color: $brand-color-2;
      display: block;
      height: 7.3rem;
      display: flex;
      align-items: center;
      transition: all .1s ease-in;
      cursor: pointer;
      &:hover{
        transform: translateX(-5rem);
        @include mq($min-width: map-get($breakpoints, xxl)) {
          transform: translateX(-8rem);
        }
      }
      svg{
        width: 100%;
        max-width: 4.3rem;
        color: $brand-color-1;
        font-size: 3.5rem;
        @include mq($min-width: map-get($breakpoints, xxl)) {
          max-width: 7.3rem;
          font-size: 4.5rem;
        }
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        margin-top: 54rem;
      }
    }
    .flag-content{
      margin-top: 30rem;
      .logo{
        display: block;
        margin-left: -2rem;
        @include mq($min-width: map-get($breakpoints, xxl)) {
          margin-left: -6rem;
        }
        svg{
          width: 22rem;
          height: 8rem;
          color: $brand-color-1;
        }
      }
      .text{
        display: block;
        color: $brand-color-1;
        font-size: 2rem;
        margin-left: -2rem;
        a{
        }
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        margin-top: 54rem;
      }
    }
  }
  .flag-basket{
    background-color: $brand-color-2;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    &.is-open{
      transform: translateX(-31.4rem);
      z-index: 2;
    }
    .flag-icon{
      margin-top: 38.2rem;
      background-color: $brand-color-2;
      display: block;
      transform: translateX(-4.3rem);
      width: 5.3rem;
      @include mq($min-width: map-get($breakpoints, xxl)) {
        transform: translateX(-7.3rem);
        width: 8.3rem;
      }
      height: 7.3rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .1s ease-in;
      &:hover{
        transform: translateX(-5rem);
        @include mq($min-width: map-get($breakpoints, xxl)) {
          transform: translateX(-8rem);
        }
      }
      svg{
        width: 100%;
        max-width: 4.3rem;
        color: $brand-color-1;
        font-size: 3.5rem;
        @include mq($min-width: map-get($breakpoints, xxl)) {
          max-width: 7.3rem;
          font-size: 4.5rem;
        }
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        margin-top: 62.2rem;
      }
    }
    .flag-content{
    }
  }
  .flag-favorites{
    background-color: $brand-color-2;
    display: flex;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    &.is-open{
      transform: translateX(-31.4rem);
      z-index: 2;
    }
    .flag-icon{
      margin-top: 46.4rem;
      background-color: $brand-color-2;
      display: block;
      transform: translateX(-4.3rem);
      width: 5.3rem;
      @include mq($min-width: map-get($breakpoints, xxl)) {
        transform: translateX(-7.3rem);
        width: 8.3rem;
      }
      height: 7.3rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all .1s ease-in;
      &:hover{
        transform: translateX(-5rem);
        @include mq($min-width: map-get($breakpoints, xxl)) {
          transform: translateX(-8rem);
        }
      }
      svg{
        width: 100%;
        max-width: 4.3rem;
        color: $brand-color-1;
        font-size: 3.5rem;
        @include mq($min-width: map-get($breakpoints, xxl)) {
          max-width: 7.3rem;
          font-size: 4.5rem;
        }
      }
      @include mq($min-width: map-get($breakpoints, xxl)) {
        margin-top: 70.4rem;
      }
    }
    .flag-content{
    }
  }
}